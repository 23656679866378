const episode1 = [
    {
        title: "Capturing Stillness",
        time: "10Am",
        text:
            "Capturing Stillness which will largely be captured by film and photography of sitting in chairs in natural environments, gardens and outdoors",
    },
    {
        title: "Capturing Stillness",
        time: "10Am",
        text:
            "Capturing Stillness which will largely be captured by film and photography of sitting in chairs in natural environments, gardens and outdoors",
    },
    {
        title: "Capturing Stillness",
        time: "10Am",
        text:
            "Capturing Stillness which will largely be captured by film and photography of sitting in chairs in natural environments, gardens and outdoors",
    },
    {
        title: "Capturing Stillness",
        time: "10Am",
        text:
            "Capturing Stillness which will largely be captured by film and photography of sitting in chairs in natural environments, gardens and outdoors",
    },
    {
        title: "Capturing Stillness",
        time: "10Am",
        text:
            "Capturing Stillness which will largely be captured by film and photography of sitting in chairs in natural environments, gardens and outdoors",
    },
    {
        title: "Capturing Stillness",
        time: "10Am",
        text:
            "Capturing Stillness which will largely be captured by film and photography of sitting in chairs in natural environments, gardens and outdoors",
    },
    {
        title: "Capturing Stillness",
        time: "10Am",
        text:
            "Capturing Stillness which will largely be captured by film and photography of sitting in chairs in natural environments, gardens and outdoors",
    },
    {
        title: "Capturing Stillness",
        time: "10Am",
        text:
            "Capturing Stillness which will largely be captured by film and photography of sitting in chairs in natural environments, gardens and outdoors",
    },
];

export default episode1